import React from 'react';
import PropTypes from 'prop-types';

import OurTalents from './OurTalentsItems/OurTalentsItems';
import Ticker from '../../../UiComponents/Ticker/Ticker';

const Portfolio = ({ dataPortfolio }) => {
  const tickers = [
    'Our',
    'Talents',
    'Our',
    'Talents',
    'Our',
    'Talents',
  ];
  return (
    <section className="home-portfolio second-section" id="second-section" data-scroll-section>
      <Ticker tickers={tickers}/>
      <OurTalents items={dataPortfolio.items} />
    </section>
  );
};

Portfolio.propTypes = {
  dataPortfolio: PropTypes.object,
};

export default Portfolio;
