import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';

import ArrowRight from '../../../../../svg/slider-arrow-right-white.svg';
import ArrowLeft from '../../../../../svg/slider-arrow-left-white.svg';
import PlayButton from '../../../../../svg/play-video.svg';

const SliderVideo = ({ videoItems, playReel }) => {

  const slider = React.createRef();

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const counter = currentSlide + 1;

  function handleAfterChange(index) {
    setCurrentSlide(index);
  }

  function SampleNextArrow() {
    return (
      <button
        className="slider-button slider-button__next"
        onClick={() => slider.current.slickNext()}
      >
        Next
        <ArrowRight />
      </button>
    );
  }

  function SamplePrevArrow() {

    return (
      <button
        className={'slider-button slider-button__prev'}
        onClick={() => slider.current.slickPrev()}
      >
        Prev
        <ArrowLeft />
      </button>
    );
  }

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    afterChange: handleAfterChange,
  };

  return (
    <>
      <div
        className="home-videos__slider-wrapper"
        // data-scroll=""
        // data-scroll-speed="2"
        // data-scroll-delay="0.05"
      >
        <Slider ref={slider} {...settings} className={'home-videos__slider'}>
          {videoItems.map((item, index) => {
            return (
              <div className="home-videos__slider-item__wrapper" key={index}>
                <div className="home-videos__slider-item">
                  <div className="home-videos__slider-item__background">
                    <video
                      src={`https://admin.zerotabletop.com${item.video_cover?.url}`}
                      loading="lazy" 
                      async=""
                      playsInline={true}
                      webkit-playsinline=""
                      autoPlay={true}
                      muted={true}
                      loop={true}
                      className="home-videos__slider-item__player"
                    ></video>
                    <button
                      className="home-videos__slider-item__play"
                      onClick={(e) => playReel(e, index)}
                    >
                      <span className="home-videos__slider-item__play-title">
                        Play Video
                      </span>
                      <span className="home-videos__slider-item__play-pulse">
                        <span className="pulse"></span>
                        <PlayButton className="pulse-icon" />
                      </span>
                    </button>
                  </div>

                  <div className="home-videos__slider-item__info">
                    <h3 className="home-videos__slider-item__info-title">
                      {item.case}
                    </h3>
                    <span className="home-videos__slider-item__info-separator"></span>
                    <span className="home-videos__slider-item__info-description">
                      Director:
                    </span>
                    <p className="home-videos__slider-item__info-name">
                      {item.director}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="home-videos__slider-nav">
          <div className="home-videos__slider-counter">
            <span className="home-videos__slider-counter__current">
              {counter <= 9 ? '0' + counter : counter}
            </span>
            <span className="home-videos__slider-counter__separator">/</span>
            <span className="home-videos__slider-counter__total">
              {videoItems.length <= 9
                ? '0' + videoItems.length
                : videoItems.length}
            </span>
          </div>
          <div className="home-videos__slider-buttons">
            <SamplePrevArrow />
            <SampleNextArrow />
          </div>
        </div>
      </div>
    </>
  );
};

SliderVideo.propTypes = {
  videoItems: PropTypes.array,
};

export default SliderVideo;
