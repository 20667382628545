import React from 'react';
import PropTypes from 'prop-types';

import SectionHeader from '../../../UiComponents/SectionHeader/SectionHeader';
import SliderVideo from './SliderVideo/SliderVideo';
import VideoModal from '../../../UiComponents/VideoModal/VideoModal';

const HomeVideos = ({ videos }) => {

  const [open, setOpen] = React.useState(false);
  const [play, setPlay] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(0);

  function playReel(e, index) {
    e.preventDefault();
    setOpen(true);
    setTimeout(() => {
      setPlay((play) => true);
    }, 3000);
    setSelectedVideo(index);
  }

  function stopReel() {
    setOpen(false);
    setPlay(false);
  }

  return (
    <>
      <section className="home-videos" data-scroll-section>
        <div className="content-wrapper">
          <SectionHeader title={videos.title} description={'04.'} />
        </div>
        <SliderVideo videoItems={videos.video_items} playReel={playReel} />
      </section>
      
      <VideoModal
        showreel={videos.video_items[selectedVideo].url}
        stopReel={stopReel}
        play={play}
        open={open}
      />
    </>
  );
};

HomeVideos.propTypes = {
  videos: PropTypes.object,
};

export default HomeVideos;
