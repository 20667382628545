import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';

import useMedia from '../../../../../hooks/useMedia';

import ArrowRight from '../../../../../svg/slider-arrow-right-black.svg';
import ArrowLeft from '../../../../../svg/slider-arrow-left-black.svg';

const Carousel = ({ handleClick, slides }) => {

  const isBig = useMedia('(max-width: 1399.98px) and (min-width: 991.98px)');
  const isMedium = useMedia('(max-width: 991.98px) and (min-width: 767.98px)');
  const isSmall = useMedia('(max-width: 767.98px)');
  const count = isBig ? 3 : isMedium ? 2 : isSmall ? 1 : 3;

  const slider = React.createRef();

  const [currentSlide, setCurrentSlide] = React.useState(0);

  function handleAfterChange(index) {
    setCurrentSlide(index);
  }

  function SampleNextArrow() {
    return (
      <button
        className="slider-button slider-button__next"
        onClick={() => slider.current.slickNext()}
      >
        Next
        <ArrowRight />
      </button>
    );
  }

  function SamplePrevArrow() {

    return (
      <button
        className={'slider-button slider-button__prev'}
        onClick={() => slider.current.slickPrev()}
      >
        Prev
        <ArrowLeft />
      </button>
    );
  }

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    // autoplay: true,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 576,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 1199.98,
        settings: { slidesToShow: 3, slidesToScroll: 3 },
      },
    ],
  };

  const counter = currentSlide + count;

  return (
    <>
      <div
        className="home-photos__slider-wrapper"
        // data-scroll=""
        // data-scroll-speed="2"
        // data-scroll-delay="0.05"
      >
        <Slider ref={slider} {...settings} className={'home-photos__slider'}>
          {slides.map((item, index) => {
            
            const image = getImage(item.photo.localFile);

            return (
              <div
                className="home-photos__slider-slide"
                key={index}
                role="presentation"
              >
                <div className="home-photos__slider-image__wrapper">
                  <div className="slide-image__hovered">
                    <button
                      className="slide-image__hovered-button"
                      onClick={(e) => handleClick(e, index)}
                    >View Image</button>
                  </div>
                  <GatsbyImage image={image}
                    className="home-photos__slider-image"
                    alt={item.case}
                  />
                </div>
                <p className="home-photos__slider-title">{item.case}</p>
              </div>
            );
          })}
        </Slider>
        <div className="home-photos__slider-navigation">
          <div className="home-photos__slider-counter">
            <span className="home-photos__slider-counter__current">
              {counter <= 9 ? '0' + counter : counter}
            </span>
            <span className="home-photos__slider-counter__separator">/</span>
            <span className="home-photos__slider-counter__total">
              {slides.length <= 9 ? '0' + slides.length : slides.length}
            </span>
          </div>
          <div className="home-photos__slider-buttons">
            <SamplePrevArrow />
            <SampleNextArrow />
          </div>
        </div>
      </div>
    </>
  );
};

Carousel.propTypes = {
  slides: PropTypes.array,
  handleClick: PropTypes.func,
};

export default Carousel;
