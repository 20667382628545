import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PropTypes from 'prop-types';


const LightBoxSecond = ({ img, closeModal, showLightbox, title }) => {
  
  const image = getImage(img.localFile);


  return (
    <div className={showLightbox ? 'lightbox is-show' : 'lightbox'}>
      <div className="lightbox-overlay" onClick={closeModal} role="presentation"></div>
      <div className="lightbox-wrapper">
        <div className="lightbox-image__wrapper">
          <GatsbyImage image={image} className="lightBox-image" alt="best foto" />
        </div>

          <button className="light-box-close" onClick={closeModal}>
            Close
          </button>
      </div>
    </div>
  );
};

LightBoxSecond.propTypes = {
  closeModal: PropTypes.func,
  showLightbox: PropTypes.bool,
  img: PropTypes.object,
};

export default LightBoxSecond;
