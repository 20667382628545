import React from 'react';
// import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/styles.sass';
import {
  MainScreenFirst,
  OurNumbers,
  HomeAbout,
  HomePhotos,
  OurClients,
  HomeVideos,
} from '../components/Pages/Home';

import OurTalents from '../components/Pages/Home/OurTalents/OurTalents'

const IndexPage = ({data}) => {

  return (
    <Layout>
      <Seo title={data.strapiHomePage.seo_title} description={data.strapiHomePage.seo_description} />
      {/* <LazyLoadComponent> */}
        <MainScreenFirst
          title={data.strapiHomePage.main.title}
          socials={data.strapiContacts.Socials}
          showreel={data.strapiHomePage.main.showreel}
          video={data.strapiHomePage.main.video_cover}
          poster={data.strapiHomePage.main.poster}
        />
      {/* </LazyLoadComponent> */}
    
      <OurTalents dataPortfolio={data.strapiAbout.about_portfolio} />
      
      {/* <HomeServices services={data.strapiHomePage.services} /> */}
      <HomeAbout 
        about={data.strapiHomePage.history} 
        desc={data.strapiAbout.History}
      />
      <OurNumbers numbers={data.strapiAbout.in_numbers} />
     
        <HomeVideos videos={data.strapiHomePage.Best_Video} />

      <HomePhotos photos={data.strapiHomePage.Best_Photo} />
      <OurClients clients={data.strapiAbout.clients} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    strapiHomePage {
      seo_title
      seo_description
      main {
        title
        showreel
        video_cover {
          url
        }
        poster {
          url
      }
      }
      services {
        title
        slug
        video {
          url
        }
      }
      history {
        title
      }
      Best_Video {
          title
          video_items {
            case
            director
            url
            video_cover {
              url
            }
          }
        }
        Best_Photo {
        title
        photos {
          case
          photo {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
            }
          }
        }
      }
    }
    strapiAbout {
      History {
        description
      }
      in_numbers {
        title
        value
      } 
      clients {
        title
        icons {
          icon {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        about_portfolio {
          title
          items {
            title
            slug
            icon
            video {
              url
            }
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
      strapiContacts {
          Socials {
              title
              link
          }
      }
    }
`