import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../../../UiComponents/ButtonLink/ButtonLink';
import SectionHeader from '../../../UiComponents/SectionHeader/SectionHeader';

const ReactMarkdown = require("react-markdown");

const HomeAbout = ({ about, desc }) => {
  
  return (
    <section className="home-about" data-scroll-section>
      <div className="content-wrapper">
        <SectionHeader title={about.title} description={'About'} />
        <div
          className="home-about__content"
          // data-scroll=""
          // data-scroll-speed="2"
          // data-scroll-delay="0.05"
        >
      
          <div className="home-about__content-wrapper">
            <div className="home-about__content-markdown">
              <ReactMarkdown source={desc.description} />
            </div>
            <div className="button-wrapper">
              <ButtonLink slug={'about'}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeAbout.propTypes = {
  about: PropTypes.object,
};

export default HomeAbout;
