import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Arrow from '../../../svg/arrow-top-right.svg';

const ButtonLink = ({slug}) => {

  return (
    <Link to={slug} className="button-link">
      <span className="button-link__bg"></span>
      <span className="button-link__title">Read more</span>
      <Arrow className="button-link__icon" />
    </Link>
  );
};

ButtonLink.propTypes = {
  slug: PropTypes.string,
};

export default ButtonLink;
