import React from 'react';
import PropTypes from 'prop-types';

import SectionHeader from '../../../UiComponents/SectionHeader/SectionHeader';
import Carousel from './Carousel/Carousel';
import LightBoxSecond from '../../../UiComponents/LightBoxSecond/LightBoxSecond';

const HomePhotos = ({ photos }) => {

  const [showLightbox, setShowLightbox] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(0);

  function handleClick(e, index) {
    e.preventDefault();
    setShowLightbox(!showLightbox);
    setSelectedImage(index);
  }

  function closeModal() {
    setShowLightbox(false);
    setSelectedImage(0);
  }

  React.useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  function handleKeyUp(e) {
    e.preventDefault();
    const { keyCode } = e;
    if (showLightbox) {
      if (keyCode === 27) {
        // Escape key
        setShowLightbox(false);
      }
    }
  }

  return (
    <>
      <section className="home-photos" data-scroll-section>
        <div className="content-wrapper">
          <SectionHeader title={photos.title} description={'05.'} />
        </div>

        <div className="content-wrapper">
          <Carousel
            slides={photos.photos}
            handleClick={handleClick}
          />
        </div>
      </section>

      <LightBoxSecond
        closeModal={closeModal}
        showLightbox={showLightbox}
        img={photos.photos[selectedImage].photo}
      />
    </>
  );
};

HomePhotos.propTypes = {
  photos: PropTypes.object,
};

export default HomePhotos;
